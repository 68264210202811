<template>
  <div class="container">
    <div class="content">
      <PageHeader title="404 Not Found" />
      <div class="page-content container-fluid">
        <p>
          Parece que não conseguimos encontrar o que você estava procurando. Se
          você digitou o endereço, verifique a ortografia.
        </p>
        <p><router-link to="/"> Dashboard </router-link></p>
        <p><a href="https://yup.chat/" target="_blank"> Website </a></p>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'e404',
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
</style>
